window.addEventListener('DOMContentLoaded', () => {
  initVideoScene();
  addParallax('[data-parallax-item], .bard');
  smartphoneScrolling();
  initHandlers();
  // initSpaceParallax();
})

const loop = window.requestAnimationFrame ||
  window.webkitRequestAnimationFrame ||
  window.mozRequestAnimationFrame ||
  window.msRequestAnimationFrame ||
  window.oRequestAnimationFrame ||
  function (callback) {
    return setTimeout(callback, 1000 / 60);
  };

function initSpaceParallax() {
  const spaceWrappers = document.querySelectorAll('.in-space');

  render();

  function render() {
    spaceWrappers.forEach((i, index) => {
      const {top, bottom} = i.getBoundingClientRect();
      if (top > window.innerHeight || bottom < 0) {
        return;
      }

      i.style.backgroundPositionY = `${top * -0.03}px, ${top * -0.04}px, ${top * -0.1}px`;
    });

    loop(render);
  }
}

function initHandlers() {
  let menuOpen = false;
  const mobileToggler = document.querySelector('[data-menu-toggle]');

  mobileToggler.addEventListener('click', (e) => {
    e.preventDefault();
    menuOpen = !menuOpen;
    document.documentElement.classList.toggle('mobile-menu-open');
  });

  document
    .querySelector('[data-menu]')
    .addEventListener('click', (e) => {
      if (e.target.tagName === 'A') {
        menuOpen = false;
        document.documentElement.classList.remove('mobile-menu-open');
      }
    })
}

let zeroPoint = Math.ceil(window.innerHeight / 2);

window.addEventListener('resize', () => {
  zeroPoint = Math.ceil(window.innerHeight / 2);
});

function parallaxRender(i, index) {
  const delta = i.getBoundingClientRect().top - zeroPoint;
  if (Math.abs(delta) > zeroPoint * 2) {
    return;
  }
  const top = Math.ceil(delta * 0.05 * (index % 3 + 1));

  i.style.transform = 'translate3d(0px,' + top + 'px, 0px)';
}

function smartphoneScrolling() {
  const VIEW_HEIGHT = 730;
  const FULL_HEIGHT = 2550;
  const START_SCROLL_RATE = .75;
  const smart = document.querySelector('.smartphone');
  const photos = document.querySelector('.smartphone__photos');

  bindScrollEvent(render);

  render();

  function render() {
    const delta = smart.getBoundingClientRect().top;
    const h = delta / window.innerHeight;

    const move = Math.ceil((FULL_HEIGHT - VIEW_HEIGHT) / FULL_HEIGHT * 150 * (h - START_SCROLL_RATE)) * -1;
    if (move > 0 && move <= 100) {
      photos.style.backgroundPositionY = move + '%';
    }
  }
}


function addParallax(selector) {
  const elements = document.querySelectorAll(selector);

  render();

  function render() {
    elements.forEach(parallaxRender);
    loop(render);
  }
}

function initVideoScene() {
  const tvBox = document.querySelector('[data-js-animation-trap]');
  const unmuteButton = document.querySelector('[data-unmute]')
  const tvVideo = document.getElementById('present-video');
  const moreText = document.getElementById('more-video');
  const screen = document.querySelector('.screen-02');
  const oneBox = document.querySelector('.screen-02__onebox');
  const replayButton = document.querySelector('[data-replay]');

  const observer = new IntersectionObserver((entries) => {
    if (entries[0].isIntersecting) {
      startScene()
      screen.classList.add('in-viewport');
      observer.disconnect();
    }
  }, {
    threshold: 0.2
  })

  observer.observe(oneBox);

  unmuteButton.addEventListener('click', (e) => {
    e.preventDefault();
    unmuteButton.classList.toggle('is-on');
    tvVideo.volume = 0.6;
    tvVideo.muted = !tvVideo.muted;
  })

  function playFirstVideo() {
    const sources = tvVideo.getElementsByTagName('source');

    moreText.classList.add('is-show');
    sources[0].src = '/video/AST_onebox_HD_part1-faststart.mp4';
    sources[1].src = '/video/AST_onebox_HD_part1-faststart.webm';
    tvVideo.load();
    tvVideo.removeEventListener('ended', endHandler);
    tvBox.classList.remove('is-ended');
    moreText.classList.remove('is-show');

    tvVideo.play();
    tvVideo.addEventListener('ended', changeVideo)
  }

  const changeVideo = () => {
    const sources = tvVideo.getElementsByTagName('source');

    moreText.classList.add('is-show');
    sources[0].src = '/video/AST_onebox_HD_part2-faststart.mp4';
    sources[1].src = '/video/AST_onebox_HD_part2-faststart.webm';
    tvVideo.load();

    tvVideo.removeEventListener('ended', changeVideo);

    setTimeout(() => {
      tvVideo.play();
      tvVideo.addEventListener('ended', endHandler)
      moreText.classList.remove('is-show');
    }, 2500);
  }

  function endHandler() {
    tvBox.classList.add('is-ended');
  }

  function startScene() {
    tvBox.addEventListener('animationend', () => {
      screen.classList.add('is-rays-show');

      setTimeout(() => {
        tvVideo.play();
        tvVideo.setAttribute('poster', '');
        tvVideo.addEventListener('ended', changeVideo);
      }, 1000);
    })
  }

  replayButton.addEventListener('click', () => {
    playFirstVideo();
  })
}

initShareBlock();

function bindScrollEvent(callback, debounce = 0) {
  window.addEventListener('scroll', () => {
    loop(callback);
  });
}

initRoomAnimation();

function initRoomAnimation() {
  const room = document.querySelector('.room-wrapper');


  const observer = new IntersectionObserver((entries) => {
    if (entries[0].isIntersecting) {
      room.classList.add('in-viewport');
    } else {
      room.classList.remove('in-viewport');

    }
  }, {
    threshold: 0.2
  })

  observer.observe(room)
}

function initShareBlock() {
  const TILE_COUNT = 20;
  const container = document.getElementById('js-share');
  const screen = document.querySelector('.screen-08');
  const smartphone = document.querySelector('.screen-08__smart');
  const existTiles = document.querySelectorAll('.screen-08__tile');
  const COLOR_LIST = [
    'rgba(172, 232, 183, 0.3)',
    'rgba(172, 232, 183, 0.3)',
    'rgba(157, 212, 167, 0.34)',
    'rgba(163, 226, 215, 0.34)',
    'rgba(237, 176, 171, 0.3)',
    'rgba(251, 193, 170, 0.3)',
    'rgba(239, 214, 179, 0.17)',
    'rgba(218, 218, 218, 0.3)',
    'rgba(189, 148, 228, 0.49)',
    'rgba(227, 244, 196, 0.49)',
    'rgba(219, 212, 191, 0.49)',
    'rgba(253, 201, 137, 0.49)',
    'rgba(241, 192, 205, 0.49)',
    'rgba(168, 184, 213, 0.11)',
    'rgba(155, 189, 206, 0.3)',
    'rgba(137, 189, 215, 0.6)',
    'rgba(172, 209, 228, 0.3)',
    'rgba(204, 227, 238, 0.1)',
  ]

  const tilesFragment = document.createDocumentFragment();

  const tilesGroup = Array.from({length: 5}, () => {

    const group = document.createElement('div');
    group.classList.add('tiles-group');
    Array
      .from({length: TILE_COUNT}, () => getRandomTile()
      ).forEach(t => {
      group.appendChild(t)
    })
    return group;
  });

  tilesGroup.forEach(t => tilesFragment.appendChild(t));

  container.appendChild(tilesFragment);

  const tiles = [...tilesGroup, ...existTiles];

  const observer = new IntersectionObserver((entries) => {
    if (entries[0].isIntersecting) {
      observer.disconnect();
      startAnimation()

      // bindScrollEvent(render);
    }
  }, {
    threshold: 0.8
  })

  observer.observe(smartphone);

  const startAnimation = () => {
    screen.classList.add('is-animated');
    setTimeout(() => {
      screen.classList.add('in-viewport');
      render();
    }, 1500);
  };

  function render() {
    tiles.forEach(parallaxRender);
    loop(render)
  }

  function getRandomTile() {
    const tile = document.createElement('div');
    tile.classList.add('screen-08__tile');

    tile.style.background = COLOR_LIST[Math.floor(Math.random() * COLOR_LIST.length)];
    tile.style.left = getRandomCoords() + '%';
    tile.style.top = getRandomCoords() * 0.8 + '%';
    tile.style.opacity = Math.ceil(Math.random() * 100) / 100;
    return tile;
  }

  function randn_bm(min, max, skew) {
    let u = 0, v = 0;
    while (u === 0) u = Math.random(); //Converting [0,1) to (0,1)
    while (v === 0) v = Math.random();
    let num = Math.sqrt(-2.0 * Math.log(u)) * Math.cos(2.0 * Math.PI * v);

    num = num / 10.0 + 0.5; // Translate to 0 -> 1
    if (num > 1 || num < 0) num = randn_bm(min, max, skew); // resample between 0 and 1 if out of range
    num = Math.pow(num, skew); // Skew
    num *= max - min; // Stretch to fill range
    num += min; // offset to min
    return num;
  }

  function getRandomCoords() {
    return randn_bm(-150, 250, 1.0);
  }
}
